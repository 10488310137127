import React from "react";

//! Import the next-seo component (carouseljsonld) **************
import { CarouselJsonLd } from "next-seo";
//! Import The courses data from JSON ***************************
import allCourses from "../../store/staticData/coursesDataRevisedv14.json";
import { coursePageUrlHelper } from "../../helpers/coursePageUrlHelper";

const CourseCarouselSEO = () => {
  //! mapCourses filters out the courses with multiple cards ( show_outside - false) ******
  //! and returns the jsonld data.
  //! **filteredCourses** holds the unique cards to be passed to the component
  const mapCourses = () => {
    const filteredCourses = allCourses.filter((courses) => {
      return courses.show_outside == true;
    });

    return filteredCourses.map((course) => {
      let courseSlug = course.display_name.toLowerCase().split(" ").join("-");
      return {
        courseName: course.name,
        description: course.pitch,
        providerName: "Spark Studio",
        url: `https://sparkstudio.co/${coursePageUrlHelper(
          courseSlug
        )}/${courseSlug}`,
      };
    });
  };
  // console.log(allCourses)
  return <CarouselJsonLd type="course" data={mapCourses()} />;
};

export default CourseCarouselSEO;
