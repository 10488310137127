import React from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import moengageEvent from "../../helpers/MoengageEventTracking";
import {
  bookTrialClickAttributes,
  buttonClickAttributes,
} from "../../helpers/MoengageAttributeCreators";
import { useSelector } from "react-redux";
import { leadsquaredActivityDispatcher } from "../../helpers/leadsquaredActivityDispatcher";
import {
  bookTrialClickActivity,
  buttonClickActivity,
} from "../../helpers/leadsquaredAttributeCreators";
import { setContext } from "../../store/actions/rootActions";
import { useDispatch } from "react-redux";
function HomepageBanner() {
  const authToken = useSelector((state) => state.auth.authToken);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const isNextEduUser = useSelector(
    (state) => state.dashboard_session.isNextEduUser
  );
  const dispatch = useDispatch();
  return (
    <div className="homepage-banner__wrapper">
      <div className="homepage-banner__left">
        <h1 className="homepage-banner__left--header">
          Unlock Your Child's
          <br />
          Confidence &<br />
          Creativity
          <br />
          {/* Online <br /> Extracurricular <br /> classes for <br /> ages 5 to 15 */}
        </h1>
        <h2 className="homepage-banner__left--subheader">
          Live Online Classes in{" "}
          <h2 className="homepage-banner__left--subheader-gradient">
            Communication
          </h2>{" "}
          | <h2 className="homepage-banner__left--subheader-gradient">Music</h2>{" "}
          | <h2 className="homepage-banner__left--subheader-gradient">Art</h2>
        </h2>
        {!isNextEduUser && (
          <PrimaryButton
            buttonText="Book a FREE trial"
            version="version-2"
            linkTo="/book-a-trial"
            // shine={true}
            clickHandle={() => {
              dispatch(setContext("trial"));
              moengageEvent(
                "Button_Click",
                buttonClickAttributes(
                  1,
                  "Book a FREE trial",
                  "/book-a-trial",
                  1,
                  4,
                  3,
                  "After Main Creative Banner"
                )
              );
              if (authToken.length > 0) {
                leadsquaredActivityDispatcher(
                  buttonClickActivity(
                    1,
                    "Book a FREE trial",
                    "/book-a-trial",
                    1,
                    3,
                    "After Main Creative Banner"
                  ),
                  authToken,
                  userDetails.id
                );
                leadsquaredActivityDispatcher(
                  bookTrialClickActivity(
                    "Book a FREE trial",
                    "/book-a-trial",
                    1,
                    "After Main Creative Banner",
                    window.location.pathname
                  ),
                  authToken,
                  userDetails.id
                );
              }
              moengageEvent(
                "Book_Trial_Click",
                bookTrialClickAttributes(
                  "Book a FREE trial",
                  "/book-a-trial",
                  1,
                  "After Main Creative Banner",
                  ""
                )
              );
            }}
          />
        )}
      </div>
      <div className="homepage-banner__right">
        <img
          src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}homepageBannerImageGirl.webp`}
          alt=""
          className="homepage-banner__right--image-1"
        />
        <img
          src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}bannerImage2.webp`}
          alt=""
          className="homepage-banner__right--image-2"
        />
      </div>
    </div>
  );
}

export default HomepageBanner;
