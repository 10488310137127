import React from "react";
//! Import VideoJsonLd from next-seo*********
import { VideoJsonLd } from "next-seo";

const VideoSEO = ({ videoDetails }) => {
   //console.log("VideoDetails",videoDetails)

  
    return (
      
      videoDetails.map((video, index) => {
      <VideoJsonLd
        keyOverride={index}
        name={video.title}
        description={video.one_liner}
        contentUrl={
          `${process.env.NEXT_PUBLIC_SINGLE_COURSE_SHOWCASE_ASSETS}${video.media_url_webm}`}
        uploadDate="2021-09-15T08:00:00+08:00"
        thumbnailUrls={[
          `${process.env.NEXT_PUBLIC_SINGLE_COURSE_SHOWCASE_ASSETS}${video.media_poster_thumbnail}`,
        ]}
      />
      })
    )
  
};

export default VideoSEO;
