import React, { useState, useEffect } from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import HomepageSectionHeader from "../headers/HomepageSectionHeader";
import HomepageUspCard from "../cards/HomepageUspCard";
import moengageEvent from "../../helpers/MoengageEventTracking";
import {
  bookTrialClickAttributes,
  buttonClickAttributes,
} from "../../helpers/MoengageAttributeCreators";
import { useSelector, useDispatch } from "react-redux";
import { leadsquaredActivityDispatcher } from "../../helpers/leadsquaredActivityDispatcher";
import {
  bookTrialClickActivity,
  buttonClickActivity,
} from "../../helpers/leadsquaredAttributeCreators";
// ! Swiper
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import { setContext } from "../../store/actions/bookingActions";
SwiperCore.use([Pagination, Navigation]);
function HomepageUspSection() {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.auth.authToken);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const isNextEduUser = useSelector(
    (state) => state.dashboard_session.isNextEduUser
  );
  // ! State for responsive mode
  const [responsiveMode, setResponsiveMode] = useState(false);
  const [slidersPerView, setSlidersPerView] = useState(3);
  // ! initial render state
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    setInitialRender(false);
  }, []);
  useEffect(() => {
    if (window.innerWidth < 992) {
      setResponsiveMode(true);
    }
    if (window.innerWidth > 545 && window.innerWidth < 769) {
      setSlidersPerView(2);
    }
    if (window.innerWidth < 545) {
      setSlidersPerView(1);
    }
  }, [initialRender]);
  const pagination = {
    clickable: true,
  };
  return (
    <div className="usp-section__wrapper">
      <HomepageSectionHeader headerContent="Why we’re awesome!" />
      {responsiveMode ? (
        <Swiper
          slidesPerView={slidersPerView}
          spaceBetween={0}
          pagination={pagination}
          className="mySwiper"
          navigation={true}
        >
          <SwiperSlide>
            <HomepageUspCard
              classNameProp="usp-section__top--card-1 usp-card"
              uspImage={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}uspCard1.svg`}
              uspHeader="Personal Attention"
              uspContent="Small group classes for individual attention on each child"
            />
          </SwiperSlide>
          <SwiperSlide>
            <HomepageUspCard
              classNameProp="usp-section__top--card-2 usp-card usp-card-reverse"
              uspImage={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}uspCardReversed5.svg`}
              uspHeader="Fun & Engaging"
              uspContent="Learn, interact and co-create with other kids. Make new friends!"
            />
          </SwiperSlide>
          <SwiperSlide>
            <HomepageUspCard
              classNameProp="usp-section__top--card-3 usp-card"
              uspImage={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}uspCard2.svg`}
              uspHeader="Top Notch Curriculum"
              uspContent="Meticulously designed for effective learning, building interest and fun!"
            />
          </SwiperSlide>
          <SwiperSlide>
            <HomepageUspCard
              classNameProp="usp-section__middle--card-1 usp-card"
              uspImage={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}uspCard3.svg`}
              uspHeader="Expert Teachers"
              uspContent="Carefully selected faculty - skilled, child-friendly and verified"
            />
          </SwiperSlide>
          <SwiperSlide>
            <HomepageUspCard
              classNameProp="usp-section__middle--card-2 usp-card"
              uspImage={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}uspCard4.svg`}
              uspHeader="Independent Learning"
              uspContent="Requires minimal parent assistance. Easy-peasy and hands-free."
            />
          </SwiperSlide>
          <SwiperSlide>
            <HomepageUspCard
              classNameProp="usp-section__bottom--card-1 usp-card usp-card-reverse"
              uspImage={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}uspCardReversed6.svg`}
              uspHeader="Certification"
              uspContent="Individual feedback and assessment, with certifications on completing levels"
            />
          </SwiperSlide>
        </Swiper>
      ) : (
        <div className="usp-section">
          <div className="usp-section__top">
            <HomepageUspCard
              classNameProp="usp-section__top--card-1 usp-card"
              uspImage={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}uspCard1.svg`}
              uspHeader="Personal Attention"
              uspContent="Small group classes for individual attention on each child"
            />
            <HomepageUspCard
              classNameProp="usp-section__top--card-2 usp-card usp-card-reverse"
              uspImage={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}uspCardReversed5.svg`}
              uspHeader="Fun & Engaging"
              uspContent="Learn, interact and co-create with other kids. Make new friends!"
            />
            <HomepageUspCard
              classNameProp="usp-section__top--card-3 usp-card"
              uspImage={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}uspCard2.svg`}
              uspHeader="Top Notch Curriculum"
              uspContent="Meticulously designed for effective learning, building interest and fun!"
            />
          </div>
          <div className="usp-section__middle">
            <HomepageUspCard
              classNameProp="usp-section__middle--card-1 usp-card"
              uspImage={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}uspCard3.svg`}
              uspHeader="Expert Teachers"
              uspContent="Carefully selected faculty - skilled, child-friendly and verified"
            />
            <HomepageUspCard
              classNameProp="usp-section__middle--card-2 usp-card"
              uspImage={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}uspCard4.svg`}
              uspHeader="Independent Learning"
              uspContent="Requires minimal parent assistance. Easy-peasy and hands-free."
            />
          </div>
          <div className="usp-section__bottom">
            <HomepageUspCard
              classNameProp="usp-section__bottom--card-1 usp-card usp-card-reverse"
              uspImage={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}uspCardReversed6.svg`}
              uspHeader="Certification"
              uspContent="Individual feedback and assessment, with certifications on completing levels"
            />
          </div>
        </div>
      )}
      {!isNextEduUser && (
        <PrimaryButton
          buttonText="Book a FREE trial"
          version="version-2"
          linkTo="/book-a-trial"
          // shine={true}
          clickHandle={() => {
            dispatch(setContext("trial"));
            moengageEvent(
              "Button_Click",
              buttonClickAttributes(
                4,
                "Book a FREE trial",
                "/book-a-trial",
                1,
                4,
                3,
                "After Attributes Section"
              )
            );
            if (authToken.length > 0) {
              leadsquaredActivityDispatcher(
                buttonClickActivity(
                  4,
                  "Book a FREE trial",
                  "/book-a-trial",
                  1,
                  3,
                  "After Attributes Section"
                ),
                authToken,
                userDetails.id
              );
            }
            moengageEvent(
              "Book_Trial_Click",
              bookTrialClickAttributes(
                "Book a FREE trial",
                "/book-a-trial",
                1,
                "After Attributes Section",
                ""
              )
            );
            if (authToken.length > 0) {
              leadsquaredActivityDispatcher(
                bookTrialClickActivity(
                  "Book a FREE trial",
                  "/book-a-trial",
                  1,
                  "After Attributes Section",
                  window.location.pathname
                ),
                authToken,
                userDetails.id
              );
            }
          }}
        />
      )}
    </div>
  );
}

export default HomepageUspSection;
