import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player/lazy";
//! SEO Component For Video **********************
import VideoSEO from "../../components/SeoComponents/VideoSEO";
// ! Swiper
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import { FBActivityDispatcher } from "../../helpers/fbConversionsHelpers/FBActivityDispatcher";
import { playVideoActivityFBPayload } from "../../helpers/fbConversionsHelpers/createFBActivityPayload";
import { useSelector } from "react-redux";
SwiperCore.use([Pagination, Navigation]);
function BannerCard({
  mediaType,
  mediaUrlWebm,
  mediaUrlMov,
  mediaUrlMp4,
  header,
  liner,
  thumbnailUrl,
  childAge,
  imageUrl,
}) {
  const authToken = useSelector((state) => state.auth.authToken);
  const userDetails = useSelector((state) => state.auth.userDetails);
  return (
    <div className="single-course-banner-card">
      {mediaType === "video" ? (
        <ReactPlayer
          className="single-course-banner-card__showcase-video"
          playing
          controls
          loop
          controlsList="nodownload"
          onPlay={() => {
            // if (authToken.length > 0) {
            //   FBActivityDispatcher(
            //     playVideoActivityFBPayload(
            //       userDetails.id,
            //       `[ ${
            //         window?.location?.pathname || "Showcase Section"
            //       } | ${header} ]`
            //     ),
            //     authToken,
            //     userDetails.id
            //   );
            // }
          }}
          url={[
            {
              src: `${process.env.NEXT_PUBLIC_SINGLE_COURSE_SHOWCASE_ASSETS}${mediaUrlWebm}`,
              type: "video/webm",
            },
            {
              src: `${process.env.NEXT_PUBLIC_SINGLE_COURSE_SHOWCASE_ASSETS}${mediaUrlMov}`,
              type: "video/mov",
            },
            {
              src: `${process.env.NEXT_PUBLIC_SINGLE_COURSE_SHOWCASE_ASSETS}${mediaUrlMp4}`,
              type: "video/mp4",
            },
          ]}
          light={`${process.env.NEXT_PUBLIC_SINGLE_COURSE_SHOWCASE_ASSETS}${thumbnailUrl}`}
          width="100%"
          height="100%"
        />
      ) : (
        <img src={imageUrl} alt="" />
      )}

      <h1>{header}</h1>
      <p>
        {liner} (Age {childAge})
      </p>
    </div>
  );
}

function NewShowcase({ showcaseDetails }) {
  const [pageStyling, setPageStyling] = useState(true);
  useEffect(() => {
    if (window.location.pathname.includes("/about-us")) {
      setPageStyling(false);
    }
  }, []);
  return (
    <div className="new-showcase__wrapper">
      <VideoSEO videoDetails={showcaseDetails} />
      <div className={pageStyling ? "new-showcase home" : "new-showcase"}>
        <div className="new-showcase__left">
          {pageStyling ? (
            <h2 className="new-showcase__left--header">
              Our Studio Super Sparks:
            </h2>
          ) : (
            <h2 className="new-showcase__left--header">
              Don't take our word for it.
              <br />
              Take the kids' word.
            </h2>
          )}

          {pageStyling ? (
            <p className="new-showcase__left--sub-header">
              Let your child join this community of talented young folks
            </p>
          ) : (
            <p className="new-showcase__left--sub-header">
              Here's what children aged 5 to 15 are making / creating at Spark
              Studio.
            </p>
          )}
        </div>
        <div className="new-showcase__right">
          <Swiper
            slidesPerView={"auto"}
            centeredSlides={true}
            className="single-course-banner-slider"
            // pagination={{ clickable: true }}
            hashNavigation={{
              watchState: true,
            }}
            navigation={true}
          >
            {showcaseDetails.map((showcase, index) => {
              return (
                <SwiperSlide key={index}>
                  <BannerCard
                    mediaType={showcase.media_type}
                    mediaUrlWebm={showcase.media_url_webm}
                    mediaUrlMov={showcase.media_url_mov}
                    mediaUrlMp4={showcase.media_url_mp4}
                    header={showcase.title}
                    liner={showcase.one_liner}
                    thumbnailUrl={showcase.media_poster_thumbnail}
                    childAge={showcase.child_age}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default NewShowcase;
