import React from "react";
import PrimaryButton from "../buttons/PrimaryButton";

const HomepageSpellingBeeSection = ({ responsiveMode }) => {
  return (
    <div className="spelling-bee-homepage-section">
      <p className="spelling-bee-homepage-section-header">
        India’s largest spelling Championship
      </p>
      <p className="spelling-bee-homepage-section-subHeader">
        Represent your School
      </p>
      {!responsiveMode ? (
        <div className="spelling-bee-homepage-section-card">
          <img
            src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}spelling-bee-homepage-card-image.webp`}
            alt=""
            loading="lazy"
            className="spelling-bee-homepage-section-card-imgDesktop"
          />
          <div className="spelling-bee-homepage-section-card-bottom">
            <PrimaryButton
              version={"version-1"}
              buttonText="Register Now"
              customWidth={true}
              customWidthValue="279px"
              clickHandle={() => {
                window.open(
                  "https://book.sparkstudio.co/spelling-bee",
                  "_blank"
                );
              }}
            />
          </div>
        </div>
      ) : (
        <div
          className="spelling-bee-homepage-section-cardMobile"
          onClick={() =>
            window.open("https://book.sparkstudio.co/spelling-bee", "_blank")
          }
        >
          <img
            src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}spelling-bee-homepage-card-image-mobile.png`}
            alt=""
            loading="lazy"
            className="spelling-bee-homepage-section-card-imgMobile"
          />
          {/* <div className="spelling-bee-homepage-section-card-bottom"> */}
          {/* <PrimaryButton
            version={"version-1"}
            buttonText="Register Now"
            customWidth={true}
            customWidthValue="279px"
            clickHandle={() => {
              window.open("https://book.sparkstudio.co/spelling-bee", "_blank");
            }}
          /> */}
          {/* </div> */}
        </div>
      )}
    </div>
  );
};

export default HomepageSpellingBeeSection;
