let newCoursePageSlugs = [
  "public-speaking",
  "western-vocals",
  "stop-motion-animation",
  "art-of-storytelling",
  "photography",
  "guitar",
  "art",
  "teded-club",
  "keyboard",
  "reading-phonics",
  "spoken-english",
  "digital-animation",
  "learn-english-songs",
];
const coursePageUrlHelper = (slug) => {
  // ! removed /course check since we are not using those pages anymore.
  return "courses";
};

export { coursePageUrlHelper };
