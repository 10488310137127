import courseDetails from "../store/staticData/coursesDataRevisedv14.json";
const getShowcaseDetails = () => {
  let requiredShowcase = [];
  courseDetails.forEach((item) => {
    if (
      item.identifier === "CO2264PS" ||
      item.identifier === "MU2263WVB" ||
      item.identifier === "VI1269AJ" ||
      item.identifier === "CO7160SD"
    ) {
      requiredShowcase = [...requiredShowcase, ...item.showcase_data];
    }
  });
  return requiredShowcase;
};
export { getShowcaseDetails };
