import React, { useEffect, useState } from "react";
import Head from "next/head";
import { NextSeo } from "next-seo";
import { useSelector, useDispatch } from "react-redux";
import NavFooterLayout from "../containers/NavFooterLayout";
import HomepageBanner from "../components/banners/HomepageBanner";
import HomepageCourses from "../components/HomepageComponents/HomepageCourses";
import HomepageCallback from "../components/HomepageComponents/HomepageCallback";
import HomepageExpertsSection from "../components/HomepageComponents/HomepageExpertsSection";
import HomepageExpertsSectionResp from "../components/HomepageComponents/HomepageExpertsSectionResp";
import HomepageShowcase from "../components/HomepageComponents/HomepageShowcase";
import HomepageUspSection from "../components/HomepageComponents/HomepageUspSection";
import Testimonial from "../components/sliders/Testimonial";
import coursesDataRevisedv14 from "../store/staticData/coursesDataRevisedv14.json";
import NewShowcase from "../components/AboutUsPageComponents/NewShowcase";
import { getShowcaseDetails } from "../helpers/getShowcaseDetails";
import HomepageSpellingBeeSection from "../components/HomepageComponents/HomepageSpellingBeeSection";
export const getStaticProps = async () => {
  return {
    props: {
      openGraphData: [
        {
          property: "og:image",
          content: `${process.env.NEXT_PUBLIC_ALL_ASSETS_API}home_page.jpg`,
          key: "ogimage",
        },
        {
          property: "og:image:width",
          content: "400",
          key: "ogimagewidth",
        },
        {
          property: "og:image:height",
          content: "300",
          key: "ogimageheight",
        },
        {
          property: "og:url",
          content: `https://sparkstudio.co/`,
          key: "ogurl",
        },
        {
          property: "og:image:secure_url",
          content: `${process.env.NEXT_PUBLIC_ALL_ASSETS_API}home_page.jpg`,
          key: "ogimagesecureurl",
        },
        {
          property: "og:title",
          content: `Spark Studio | Online Learning for Kids`,
          key: "ogtitle",
        },
        {
          property: "og:description",
          content: "Live Online Classes in Communication, Music & Art",
          key: "ogdesc",
        },
        {
          property: "og:type",
          content: "website",
          key: "website",
        },
      ],
      coursesDataNew: coursesDataRevisedv14,
    },
  };
};
export default function Home({ coursesDataNew }) {
  const SEO = {
    title: "Spark Studio | Online Learning for Kids",
    description: "Live Online Classes in Communication, Music & Art",
  };
  const allCourses = useSelector((state) => state.auth.allCourses);
  const [responsiveMode, setResponsiveMode] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.innerWidth < 992) {
      setResponsiveMode(true);
    }
  }, []);
  return (
    <NavFooterLayout coursesDataNew={coursesDataNew}>
      <NextSeo {...SEO} />
      <Head>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="spark-homepage">
        <HomepageBanner />
        <HomepageCourses />
        <NewShowcase showcaseDetails={getShowcaseDetails()} />
        {/* <HomepageShowcase /> */}

        {responsiveMode ? (
          <HomepageExpertsSectionResp hideButton={false} />
        ) : (
          <HomepageExpertsSection hideButton={false} />
        )}
        <HomepageUspSection />
        {/* <TestimonialSlider /> */}
        <Testimonial />
        <HomepageSpellingBeeSection responsiveMode={responsiveMode} />
        <HomepageCallback />
      </div>
    </NavFooterLayout>
  );
}
