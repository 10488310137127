import React from "react";
import moengageEvent from "../../helpers/MoengageEventTracking";
import { cardClickAttributes } from "../../helpers/MoengageAttributeCreators";
import { leadsquaredActivityDispatcher } from "../../helpers/leadsquaredActivityDispatcher";
import { cardClickActivity } from "../../helpers/leadsquaredAttributeCreators";
import { useSelector } from "react-redux";
import { coursePageUrlHelper } from "../../helpers/coursePageUrlHelper";
import { openInNewTab } from "../../helpers/openInNewTab";
function HomepageCourseCard({
  courseImage,
  courseName,
  courseLiner,
  courseContent,
  courseTags,
  verticalThemeColorLight,
  verticalThemeColorDark,
  cardId,
  cardAge,
  cardFormat,
  clickHandle,
  freeTag,
  workshopId,
  courseUrlSlug,
}) {
  const coursesCategory = {
    guitar: "music",
    keyboard: "music",
    "learn-english-songs": "music",
    "western-vocals": "music",
    "art-of-storytelling": "speaking",
    "public-speaking": "speaking",
    "reading-phonics": "speaking",
    "spoken-english": "speaking",
    "teded-club": "speaking",
    art: "visual-art",
    "digital-animation": "visual-art",
    photography: "visual-art",
    "stop-motion-animation": "visual-art",
  };
  const authToken = useSelector((state) => state.auth.authToken);
  const userDetails = useSelector((state) => state.auth.userDetails);
  return (
    <div
      className="homepage-course-card"
      style={{
        background: `linear-gradient(111.29deg,${verticalThemeColorDark}88 -1.83%,rgba(255, 255, 255, 0) 109.95%)`,
      }}
      onClick={() => {
        // let courseSlug = courseName.toLowerCase().split(" ").join("-");
        if (cardId && cardFormat) {
          moengageEvent(
            "Card_Click",
            cardClickAttributes(
              cardId,
              courseName,
              "",
              `/${coursePageUrlHelper(courseUrlSlug)}/${courseUrlSlug}`,
              cardAge,
              cardFormat
            )
          );
          if (authToken.length > 0) {
            leadsquaredActivityDispatcher(
              cardClickActivity(
                cardId,
                courseName,
                "-",
                `/${coursePageUrlHelper(courseUrlSlug)}/${courseUrlSlug}`,
                cardAge,
                cardFormat
              ),
              authToken,
              userDetails.id
            );
          }
        }
        if (!clickHandle) {
          if (
            courseUrlSlug === "spark-studio's-teded-club" ||
            courseUrlSlug === "spark-studio's-ted-ed-club"
          )
            openInNewTab("/courses/teded-club");
          else {
            if (courseUrlSlug === "art-of-storytelling")
              openInNewTab("/courses/speaking/storytelling");
            else
              openInNewTab(
                `/${coursePageUrlHelper(courseUrlSlug)}/${
                  coursesCategory[courseUrlSlug]
                }/${courseUrlSlug}`
              );
          }
        } else {
          clickHandle(workshopId);
        }
      }}
    >
      {freeTag ? (
        <div className="free-tag">
          <img
            src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}freetag.svg`}
            alt=""
          />
        </div>
      ) : null}
      <div className="homepage-course-card__top">
        <img src={courseImage} alt="" />
      </div>
      <div className="homepage-course-card__bottom">
        <h1 className="homepage-course-card__bottom--liner">{courseLiner}</h1>
        <h1 className="homepage-course-card__bottom--title">{courseName}</h1>
        <p className="homepage-course-card__bottom--content">{courseContent}</p>
        <div className="homepage-course-card__bottom--tags">
          {courseTags?.map((tag, index) => {
            return (
              <div className="course-tag" key={index}>
                {tag}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default HomepageCourseCard;
