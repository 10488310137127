import React, { useState, useEffect } from "react";
import HomepageCourseCard from "../cards/HomepageCourseCard";
import HomepageSectionHeader from "../headers/HomepageSectionHeader";
import Link from "next/link";
import MoengageEventTracking from "../../helpers/MoengageEventTracking";
import {
  ageFilterAttributes,
  bookTrialClickAttributes,
  buttonClickAttributes,
} from "../../helpers/MoengageAttributeCreators";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../buttons/PrimaryButton";
import moengageEvent from "../../helpers/MoengageEventTracking";
import { leadsquaredActivityDispatcher } from "../../helpers/leadsquaredActivityDispatcher";
import {
  ageFilterActivity,
  bookTrialClickActivity,
  buttonClickActivity,
} from "../../helpers/leadsquaredAttributeCreators";
import { setContext } from "../../store/actions/rootActions";
//! SEO (CourseCarousel)
import CourseCarouselSEO from "../SeoComponents/CourseCarouselSEO";
// ! Swiper
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

SwiperCore.use([Pagination, Navigation]);

function HomepageCourses({ coursesDataRevised }) {
  const authToken = useSelector((state) => state.auth.authToken);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const isNextEduUser = useSelector(
    (state) => state.dashboard_session.isNextEduUser
  );
  var cardsData = useSelector((state) => state.auth.allCourses);
  const dispatch = useDispatch();
  // ! State for responsive mode
  const [responsiveMode, setResponsiveMode] = useState(false);
  // ! initial render state
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    setInitialRender(false);
    if (cardsData.length === 0) {
      cardsData = coursesDataRevised;
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth < 992) {
      setResponsiveMode(true);
    }
  }, [initialRender]);
  const pagination = {
    clickable: true,
  };
  // ! Filter application
  const [filterRange, setFilterRange] = useState([5, 15]);
  const shouldRenderCard = (min, max) => {
    for (let i = filterRange[0]; i <= filterRange[1]; i++) {
      if (i >= min && i <= max) return true;
    }
    return false;
  };
  // ! Set active class
  const setActiveClass = (activeFilter) => {
    if (filterRange.join("") === activeFilter.join("")) {
      return "homepage-courses__age-filter-item active";
    } else {
      return "homepage-courses__age-filter-item";
    }
  };
  return (
    <div className="homepage-courses">
      <CourseCarouselSEO />
      <HomepageSectionHeader
        headerContent="Our Courses"
        linerContent="Nurture. Inspire. Unleash."
      />
      <div className="homepage-courses__top">
        {/* <ul className="homepage-courses__age-filter">
          <li
            className={setActiveClass([5, 15])}
            onClick={() => {
              let attr = ageFilterAttributes(1, [5 - 15]);
              MoengageEventTracking("Age_filter", attr);
              setFilterRange([5, 15]);
              if (authToken.length > 0) {
                leadsquaredActivityDispatcher(
                  ageFilterActivity(1, "5-15"),
                  authToken,
                  userDetails.id
                );
              }
            }}
          >
            All Ages
          </li>
          <li
            className={setActiveClass([5, 5])}
            onClick={() => {
              let attr = ageFilterAttributes(2, [5, 5]);
              MoengageEventTracking("Age_filter", attr);
              setFilterRange([5, 5]);
              if (authToken.length > 0) {
                leadsquaredActivityDispatcher(
                  ageFilterActivity(5, "5-5"),
                  authToken,
                  userDetails.id
                );
              }
            }}
          >
            5 Yrs
          </li>

          <li
            className={setActiveClass([6, 7])}
            onClick={() => {
              let attr = ageFilterAttributes(2, [6, 7]);
              MoengageEventTracking("Age_filter", attr);
              setFilterRange([6, 7]);
              if (authToken.length > 0) {
                leadsquaredActivityDispatcher(
                  ageFilterActivity(6, "6-7"),
                  authToken,
                  userDetails.id
                );
              }
            }}
          >
            6-7 Yrs
          </li>
          <li
            className={setActiveClass([8, 9])}
            onClick={() => {
              let attr = ageFilterAttributes(3, [8, 9]);
              MoengageEventTracking("Age_filter", attr);
              setFilterRange([8, 9]);
              if (authToken.length > 0) {
                leadsquaredActivityDispatcher(
                  ageFilterActivity(8, "8-9"),
                  authToken,
                  userDetails.id
                );
              }
            }}
          >
            8-9 Yrs
          </li>
          <li
            className={setActiveClass([10, 11])}
            onClick={() => {
              let attr = ageFilterAttributes(4, [10, 11]);
              MoengageEventTracking("Age_filter", attr);
              setFilterRange([10, 11]);
              if (authToken.length > 0) {
                leadsquaredActivityDispatcher(
                  ageFilterActivity(10, "10-11"),
                  authToken,
                  userDetails.id
                );
              }
            }}
          >
            10-11 Yrs
          </li>
          <li
            className={setActiveClass([12, 15])}
            onClick={() => {
              let attr = ageFilterAttributes(5, [12, 15]);
              MoengageEventTracking("Age_filter", attr);
              setFilterRange([12, 15]);
              if (authToken.length > 0) {
                leadsquaredActivityDispatcher(
                  ageFilterActivity(12, "12-15"),
                  authToken,
                  userDetails.id
                );
              }
            }}
          >
            12-15 Yrs
          </li>
          <Link
            href="/courses"
            onClick={() => {
              moengageEvent(
                "Button_Click",
                buttonClickAttributes(
                  13,
                  "See All",
                  "/courses",
                  11,
                  2,
                  2,
                  "On the Homepage"
                )
              );
              if (authToken.length > 0) {
                leadsquaredActivityDispatcher(
                  buttonClickActivity(
                    13,
                    "See All",
                    "/courses",
                    11,
                    2,
                    "On the Homepage"
                  ),
                  authToken,
                  userDetails.id
                );
              }
            }}
          >
            <a className="homepage-courses__age-filter-item--see-all">
              SEE ALL
            </a>
          </Link>
        </ul> */}
      </div>
      {responsiveMode ? (
        <Swiper
          slidesPerView={"auto"}
          hashNavigation={{
            watchState: true,
          }}
          navigation={true}
          spaceBetween={0}
          pagination={pagination}
          className="mySwiper"
        >
          {cardsData.map((course, index) => {
            if (course.courseStatus === "ACTIVE" && course.showOutside)
              if (
                shouldRenderCard(
                  course.showOutsideMinAge,
                  course.showOutsideMaxAge
                )
              )
                return (
                  <SwiperSlide key={index}>
                    <HomepageCourseCard
                      key={index}
                      courseName={course.displayName}
                      courseContent={course.courseContent}
                      courseLiner={course.courseLiner}
                      courseUrlSlug={course.urlSlug}
                      courseTags={course.courseTags}
                      cardId={course.moHomepageCardId}
                      cardAge={`${course.minAge}-${course.maxAge}`}
                      cardFormat="Basic Course - I"
                      courseImage={`${
                        process.env.NEXT_PUBLIC_ALL_COURSES_IMAGES_API
                      }${course.courseId.toLowerCase()}`}
                      verticalThemeColorDark={course.verticalThemeColorDark}
                    />
                  </SwiperSlide>
                );
          })}
        </Swiper>
      ) : (
        <Swiper
          slidesPerView={"auto"}
          hashNavigation={{
            watchState: true,
          }}
          navigation={true}
          spaceBetween={0}
          pagination={pagination}
          className="mySwiper"
        >
          {cardsData.map((course, index) => {
            if (course.courseStatus === "ACTIVE" && course.showOutside) {
              if (
                shouldRenderCard(
                  course.showOutsideMinAge,
                  course.showOutsideMaxAge
                )
              )
                return (
                  <SwiperSlide key={index}>
                    <HomepageCourseCard
                      key={index}
                      courseUrlSlug={course.urlSlug}
                      courseName={course.displayName}
                      courseContent={course.courseContent}
                      courseLiner={course.courseLiner}
                      courseTags={course.courseTags}
                      courseImage={`${
                        process.env.NEXT_PUBLIC_ALL_COURSES_IMAGES_API
                      }${course.courseId.toLowerCase()}`}
                      verticalThemeColorDark={course.verticalThemeColorDark}
                    />
                  </SwiperSlide>
                );
            }
          })}
        </Swiper>
      )}
      {!isNextEduUser && (
        <PrimaryButton
          buttonText="Book a FREE trial"
          version="version-2"
          linkTo="/book-a-trial"
          // shine={true}
          clickHandle={() => {
            dispatch(setContext("trial"));
            MoengageEventTracking(
              "Button_Click",
              buttonClickAttributes(
                2,
                "Book a FREE trial",
                "/book-a-trial",
                1,
                4,
                3,
                "After Course Carousel"
              )
            );
            if (authToken.length > 0) {
              leadsquaredActivityDispatcher(
                buttonClickActivity(
                  2,
                  "Book a FREE trial",
                  "/book-a-trial",
                  1,
                  3,
                  "After Course Carousel"
                ),
                authToken,
                userDetails.id
              );
            }
            moengageEvent(
              "Book_Trial_Click",
              bookTrialClickAttributes(
                "Book a FREE trial",
                "/book-a-trial",
                1,
                "After Course Carousel",
                ""
              )
            );
            if (authToken.length > 0) {
              leadsquaredActivityDispatcher(
                bookTrialClickActivity(
                  "Book a FREE trial",
                  "/book-a-trial",
                  1,
                  "After Course Carousel",
                  window.location.pathname
                ),
                authToken,
                userDetails.id
              );
            }
          }}
        />
      )}
    </div>
  );
}

export default HomepageCourses;
